.inputBlock{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    span{
        font-size: 14px;
    }
    input{
        height: 40px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #959595;
        font-size: 15px;
    }
    textarea{
        min-height: 100px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #959595;
        font-size: 15px;

    }
}
.inputsBox{
    border: 1px dashed #00AB9C;
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 30px;

}

.button_send{
    background-color: #00AB9C;
    padding: 15px 20px;
    border: 0;
    color: #fff;
    cursor: pointer;
    &:disabled{
        background-color: grey !important;
    }
}
.butt{
    background-color: #00AB9C;
    padding: 5px 10px;
    border: 0;
    color: #fff;
    height: 35px;
    cursor: pointer;
    &:disabled{
        background-color: grey !important;
    }
}

.titlesDocs{
    display: flex;
    flex-direction: column;
    &_title{
        max-width: 800px;
        font-size: 14px;
    }
    &_desc{
        font-size: 12px;
        color: grey;
    }
    &_buttons{
        display: flex;
        flex-direction: column;
        gap: 10px;
        button{
            background-color: #00AB9C;
            cursor: pointer;
            padding: 5px 10px;
            border: 0;
            color: #fff;
            height: 35px;
            &:disabled{
                background-color: grey;
            }
        }
    }
}
.infoList{
    display: flex;
    flex-direction: column;
    &_item{
        display: flex;
        flex-direction: column;
        padding: 15px 25px;
        margin-bottom: 12px;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.3);
        -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.3);
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.3);
        border-radius: 8px;
    }
    &_title {
        font-weight: 600;
        font-size: 18px;
    }
    &_desc {
        font-size: 14px;
        line-height: 17px;
    }
}